<template>
  <!-- 基本信息 -->
  <div class="box100">
    <el-form class="settingInfoBox" :model="basicInfo" :rules="basicInfoRule" ref="basicInfoForm">
      <div class="basicClass">
        <p class="formTitle">基本信息</p>
        <div class="flexCB">
          <el-form-item label="优惠卡名称" class="detailFormItem" prop="mcaname">
            <el-input v-model="basicInfo.mcaname"  placeholder="请输入优惠卡名称" maxlength="20"></el-input>
          </el-form-item>
          <div class="flexCB detailFormItem">
            <el-form-item label="优惠卡分组" class="detailFormItem" prop="mgid_one">
              <el-select v-model="basicInfo.mgid_one" placeholder="请选择优惠卡一级分类"  @change="oneChange">
                <el-option v-for="item in mgOneList" :key="item.index" :label="item.mgname" :value="item.mgid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="detailFormItem" prop="mgid_two" style="margin-bottom: -3px;">
              <el-select v-model="basicInfo.mgid_two" placeholder="请选择优惠卡二级分类">
                <el-option v-for="item in mgTwoList" :key="item.index" :label="item.mgname" :value="item.mgid"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="flexCB">
          <el-form-item label="套餐卡类型" class="detailFormItem fiLabelLeft" prop="mcatype">
            <el-radio-group v-model="basicInfo.mcatype">
              <el-radio :label="1">次卡</el-radio>
              <el-radio :label="2">年卡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发卡数量" class="detailFormItem" prop="mcanum">
            <el-input v-model="basicInfo.mcanum"  placeholder="请输入发卡数量"></el-input>
          </el-form-item>
          <!-- <el-form-item label="展示位置" class="detailFormItem" prop="mcasort">
            <el-input v-model="basicInfo.mcasort"  placeholder="请输入优惠卡展示位置"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="排序" class="detailFormItem fiLabelLeft" prop="mgid_one">
            <el-select v-model="basicInfo.mgid_one" placeholder="请选择排序方式"  @change="oneChange">
              <el-option v-for="item in mgOneList" :key="item.index" :label="item.mgname" :value="item.mgid"></el-option>
            </el-select>
          </el-form-item> -->
        </div>
        <div class="flexCB">
          <el-form-item label="价格" class="detailFormItem" prop="mcaprice">
            <el-input v-model="basicInfo.mcaprice" placeholder="请输入价格"></el-input>
          </el-form-item>
          <el-form-item label="绩效提成" class="detailFormItem" prop="mcaperfo">
            <el-input v-model="basicInfo.mcaperfo" placeholder="请输入绩效提成金额"></el-input>
          </el-form-item>
          <!-- <el-form-item label="消费次数" class="detailFormItem" prop="mcacount">
            <el-input v-model="basicInfo.mcacount"  placeholder="请输入消费次数" disabled></el-input>
          </el-form-item> -->
        </div>
        <div class="flexCB">
          <el-form-item label="适用人群" class="detailFormItem fiLabelLeft" prop="mcadapt">
            <el-radio-group v-model="basicInfo.mcadapt">
              <el-radio :label="1">全部用户</el-radio>
              <el-radio :label="2">新用户</el-radio>
              <el-radio :label="3">老用户</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发放方式" class="detailFormItem" prop="mcamethod">
            <el-select v-model="basicInfo.mcamethod" placeholder="请选择发放方式" style="width: 100%;">
              <el-option v-for="item in mcamethodList" :key="item.index" :label="item.sysvalue" :value="item.sysid"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexSC">
          <el-form-item label="优惠内容" class="detailFormItem" prop="mcacontent">
            <el-input v-model="basicInfo.mcacontent" :rows="3" type="textarea" class="resizeNone" maxlength="200" placeholder="请输入优惠内容(最多200字)"></el-input>
          </el-form-item>
        </div>
        <div class="formClass">
          <el-form-item label="有效期" prop="mcaeffe" class="fiLabelLeft">
            <p><el-radio v-model="basicInfo.mcaeffe" :label="1">永久</el-radio></p>
            <div class="flexAC">
              <el-radio v-model="basicInfo.mcaeffe" :label="2">固定时段</el-radio>
              <el-date-picker
                v-model="basicInfo.time"
                type="daterange"
                style="width: 40%;"
                :disabled="basicInfo.mcaeffe != 2"
                value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="flexAC" style="margin-top:20px;">
              <el-radio v-model="basicInfo.mcaeffe" :label="3">自购买起</el-radio>
              <el-input v-model="basicInfo.mcadays" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入时间" :disabled="basicInfo.mcaeffe != 3" style="width: 150px;margin-right: 10px;"></el-input>天
            </div>
            <div class="flexAC" style="margin-top:20px;">
              <el-radio v-model="basicInfo.mcaeffe" :label="4">自消费起</el-radio>
              <el-input v-model="basicInfo.mcadays" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="请输入时间" :disabled="basicInfo.mcaeffe != 4" style="width: 150px;margin-right: 10px;"></el-input>天
            </div>
          </el-form-item>
        </div>
        <div class="buyBox">
            <div class="formTitle">购买须知 <p class="zdyP" @click="tagAdd">自定义添加</p></div>
            <div class="zdyXzBox">
              <div class="zdyRow" v-for="(item, index) in basicInfo.mcamark" :key="item.index">
                <el-input v-model="item.title"  placeholder="请输入标题(最多6字)" maxlength="6" class="zdyLabel"></el-input>
                <span class="surDel" @click="tagDel(index)">删除</span>
                <el-input class="formTextarea" v-model="item.info" type="textarea" maxlength="200" placeholder="请输入内容(最多200字)"></el-input>
              </div>
            </div>
          </div>
      </div>
    </el-form>

    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 0px;right: 0px;">
      <div class="settingCancel" @click="infoCancel">取消</div>
      <div class="settingConfirm" @click="infoConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import { markcardAdd, markcardUpdate, markgroDown, markgroLow, markidGet } from '@/api/market.js'
import { sysGet } from '@/api/system.js'
export default {
  props: ['serveInfoEdit'],
  data () {
    const checkGroup = (rule, value, callback) => {
      if (this.basicInfo.mgid_one !== '') {
        if (this.mgTwoList.length !== 0) {
          if (value === '') {
            return callback(new Error('请选择二级分组'))
          }
        }
      }
      callback()
    }
    const moneyReg = (rule,value,callback) => {
      if(value.length > 10){
        callback(new Error('长度在 0 到 10 个字符'));
      }else{
        setTimeout(() => {
          if (!/^\d+[.]?\d{0,2}$/.test(value) && value){
            callback(new Error('请输入正整数或小数保留两位小数'));
          }else{
            callback()
          }
        }, 500);
      }
    };
    return {
      basicInfo: {
        mcaname: '', //套餐信息名称
        mgid_one: '', //套餐一级分组
        mgid_two: '', //套餐二级分组
        mcatype: 1, //套餐类型（1：次数购买  2：折扣   3：满减）
        mcasort: 1, //套餐展示位置
        mcaprice: '', //套餐价格
        mcacount: 0, //套餐消费次数
        mcamethod: '', //套餐发放方式（1：平台发放）
        mcanum: '', //套餐发卡数量
        mcadapt: 1, //套餐适用人群（1：全部用户  2：新用户  3：老用户）
        mcacontent: '', //套餐优惠内容
        mcaeffe: 1, //有效期（1：永久  2：固定时段  3：购买天数）
        mcastime: '', //固定时段开始时间
        mcaetime: '', //固定时段结束时间
        mcadays: '', //购买天数
        time: [],
        mcaperfo: '', //绩效提成
        mcamark: [
          { title: '', info: '' }
        ]
      },
      basicInfoRule: {
        mcaname: [
          { required: true, trigger: 'blur', message: '服务名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        mgid_one: { required: true, trigger: 'blur', message: '请选择优惠卡一级分类' },
        // mgid_two: { required: true, trigger: 'blur', message: '请选择优惠卡二级分类' },
        mgid_two: { required: true, validator: checkGroup, trigger: 'blur' },
        mcatype: { required: true, trigger: 'blur', message: '请选择优惠卡类型' },
        // mcasort: { required: true, trigger: 'blur', message: '请输入优惠卡展示位置' },
        mcaprice: [
          { required: true, trigger: 'blur', message: '请输入价格' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mcacount: [
          { required: true, trigger: 'blur', message: '请输入消费次数' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mcamethod: { required: true, trigger: 'blur', message: '请选择发放方式' },
        mcanum: [
          { required: true, trigger: 'blur', message: '请输入发卡数量' },
          { validator: moneyReg, trigger: 'blur' }
        ],
        mcadapt: { required: true, trigger: 'blur', message: '请选择适用人群' },
        mcacontent: { required: true, trigger: 'blur', message: '请输入优惠内容' },
        mcaeffe: { required: true, trigger: 'blur', message: '请选择有效期' },
        mcaperfo: [
          { required: true, trigger: 'blur', message: '请输入绩效提成' },
          { validator: moneyReg, trigger: 'blur' }
        ]
      },
      mgOneList: [],
      mgTwoList: [],
      mcamethodList: [],
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    // 优惠卡一级分类下拉
    getInit () {
      markgroDown().then(res => {
        if (res.status === 1) {
          this.mgOneList = res.data.data
        } else {
          this.mgOneList = []
        }
      })
    },
    // 优惠卡二级分类下拉
    oneChange (val) {
      this.mgTwoList = []
      this.basicInfo.mgid_two = ''
      markgroLow({ mgid: val }).then(res => {
        if (res.status === 1) {
          this.mgTwoList = res.data.data
        }
      })
    },
    // 编辑表单【二级】
    oneChangeEdit (val) {
      markgroLow({ mgid: val }).then(res => {
        if (res.status === 1) {
          this.mgTwoList = res.data.data
        }
      })
    },
    init () {
      // 查询
      if (this.serveInfoEdit !== null) {
        markidGet({ mcaid: this.serveInfoEdit.mcaid }).then(res => {
          if (res.status === 1) {
            this.basicInfo = res.data.data[0]
            this.oneChangeEdit(this.basicInfo.mgid_one)
            if (this.basicInfo.mcaeffe == 2) {
              this.basicInfo.time = [this.basicInfo.mcastime, this.basicInfo.mcaetime]
            }
          } else {
            this.basicInfo = {}
          }
        })
      }

      // 发放方式
      sysGet({ systype: 'mcamethod' }).then(res => {
        if (res.status === 1) {
          this.mcamethodList = res.data.data
        } else {
          this.mcamethodList = []
        }
      })
    },
    // 取消
    infoCancel () {
      this.$emit('HandCancel')
    },
    // 购买须知 添加
    tagAdd () {
      const obj = { title: '', info: '' }
      this.basicInfo.mcamark.push(obj)
    },
    // 购买须知 删除
    tagDel (eq) {
      this.basicInfo.mcamark.splice(eq, 1)
    },
    // 基本信息提交
    infoConfirm () {
      let _st = ''
      let _et = ''
      if (this.basicInfo.mcaeffe === 2) {
        if (this.basicInfo.time.length == 0) {
          this.$message.warning('请选择时间')
          return
        } else {
          _st = this.basicInfo.time[0]
          _et = this.basicInfo.time[1]
        }
      }
      if(this.basicInfo.mcamark.some(item=>item.title === '' || item.info === '')) return this.$message.warning('请输入购买须知')
      this.$refs.basicInfoForm.validate((valid) => {
        if (!valid) return
        const params = {
          mcaid: this.basicInfo.mcaid,  //套餐ID
          mcaname: this.basicInfo.mcaname,  //套餐信息名称
          mgid_one: this.basicInfo.mgid_one,  //套餐一级分组
          mgid_two: this.basicInfo.mgid_two,  //套餐二级分组
          mcatype: this.basicInfo.mcatype,  //套餐类型（1：次数购买  2：折扣   3：满减）
          mcasort: this.basicInfo.mcasort,  //套餐展示位置
          mcaprice: this.basicInfo.mcaprice, //套餐价格
          mcacount: this.basicInfo.mcacount,  //套餐消费次数
          mcamethod: this.basicInfo.mcamethod, //套餐发放方式（1：平台发放）
          mcanum: this.basicInfo.mcanum, //套餐发卡数量
          mcadapt: this.basicInfo.mcadapt, //套餐适用人群（1：全部用户  2：新用户  3：老用户）
          mcacontent: this.basicInfo.mcacontent, //套餐优惠内容
          mcaeffe: this.basicInfo.mcaeffe, //有效期（1：永久  2：固定时段  3：购买天数
          mcastime: _st, //固定时段开始时间
          mcaetime: _et, //固定时段结束时间
          mcadays: this.basicInfo.mcadays,  //购买天数
          mcamark: this.basicInfo.mcamark,  //购买须知
          mcaperfo: this.basicInfo.mcaperfo //绩效提成
        }
        if (this.serveInfoEdit === null) { // 新增提交
          markcardAdd(params).then(res => {
            if (res.status === 1) {
              this.$alert('新增套餐卡基本信息成功，请尽快完善“适用门店” “适用服务” “配套抵扣券”信息', '保存成功', {
                confirmButtonText: '确定',
                callback: action => {
                  this.$emit('formSuccess')
                }
              })
            } else {
              this.$message.error(res.message)
            }
          })
        } else {  // 编辑提交
          markcardUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('formSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    }
  }
}
</script>

<style  scoped>
.zdyP{
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #3C84EF;
  cursor: pointer;
}
.zdyRow{
  margin-bottom: 20px;
}
.zdyRow:last-child{
  margin-bottom: 0px;
}
.zdyLabel{
  margin-bottom: 8px;
  margin-right: 10px;
  width: 50%;
}
.surDel{
  color: #F44C8E;
  cursor: pointer;
}
</style>
