<template>
<!-- 套餐卡列表 -->
  <div class="box100">
    <div class="whiteBg" v-if="!showForm">
      <template v-if="roleBtn[0].power">
        <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
      </template>
      <div class="flexAC" style="margin: 16px 0px;">
        <el-select v-model="mcatype" placeholder="类型" class="retSelect" style="width: 150px;">
          <el-option v-for="item in mcatypeList" :key="item.index" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="ishelf" placeholder="状态" class="retSelect" style="width: 150px;">
          <el-option v-for="item in ishelfList" :key="item.index" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="mgid_one" placeholder="请选择优惠卡一级分类" class="retSelect"  @change="oneChange">
          <el-option v-for="item in mgOneList" :key="item.index" :label="item.mgname" :value="item.mgid"></el-option>
        </el-select>
        <el-select v-model="mgid_two" placeholder="请选择优惠卡二级分类" class="retSelect">
          <el-option v-for="item in mgTwoList" :key="item.index" :label="item.mgname" :value="item.mgid"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="marketList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="卡券名称"
            prop="mcaname"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            label="类型"
            prop="mcatype">
            <template slot-scope="scope">
              <span v-if="scope.row.mcatype == 1" >次卡</span>
              <span v-if="scope.row.mcatype == 2" >年卡</span>
<!--              <span v-if="scope.row.mcatype == 3" >满减</span>-->
            </template>
          </el-table-column>
          <el-table-column
            label="发放方式"
            prop="sysvalue">
          </el-table-column>
          <el-table-column
            label="优惠内容"
            prop="mcacontent"
            show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="mcanum"
            label="已领取/已使用/剩余">
            <template slot-scope="scope">
              <span>{{scope.row.isoknum}}/{{scope.row.isnonum}}/{{scope.row.issnum}}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="适用门店"
            prop="shop_count">
          </el-table-column>
          <el-table-column
            label="状态"
            prop="ishelf">
            <template slot-scope="scope">
              <span v-if="scope.row.ishelf == true" >已上架</span>
              <span v-if="scope.row.ishelf == false" >已下架</span>
            </template>
          </el-table-column>
          <el-table-column
            label="排序"
            prop="mcasort">
            <template slot-scope="scope">
              <el-button type="text" style="color:#3C84EF" size="small" @click="changeSort(scope.row)">{{scope.row.mcasort}}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[3].power">
              <el-button type="text" style="color:#44B557" size="small" @click="handishelf(scope.row)"  v-if="scope.row.ishelf == false">上架</el-button>
              <el-button type="text" style="color:#F44C8E" size="small" @click="handishelf(scope.row)"  v-if="scope.row.ishelf == true">下架</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
        </el-table>

        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <div class="box100" v-if="showForm">
      <MarkForm :serveInfoEdit="serveInfoEdit" @formCancel="formCancel" @formSuccess="formSuccess"></MarkForm>
    </div>
    <!-- 套餐卡排序弹窗 -->
    <el-dialog title="排序" :visible.sync="sortShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="sortCancel">
      <el-form :model="sortForm" :rules="sortRule" ref="sortLabel">
        <el-form-item label="排序" prop="sort">
          <el-select v-model="sortForm.sort" placeholder="下拉选择排序" style="width: 100%;" >
            <el-option v-for="item in sortList" :key="item.id" :label="item.sort" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="sortCancel">取消</div>
        <div class="settingConfirm" @click="sortConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MarkForm from '@/components/marketing/markForm'
import { markcardGet, markcardDelete, markserveIshelf, markgroDown, markgroLow, markdeSortGet, markdeSortUpdate } from '@/api/market.js'
export default {
  components: {
    MarkForm
  },
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      mcatype: '',
      mcatypeList: [{ label: '次卡', value: 1 }, { label: '年卡', value: 2 }],
      ishelf: '',
      ishelfList: [{ label: '已上架', value: true }, { label: '已下架', value: false }],
      marketList: [],
      serveInfoEdit: {},
      showForm: false,
      mgOneList: [], // 一级分组
      mgTwoList: [], // 二级分组
      mgid_one: '',
      mgid_two: '',
      sortShow: false,
      sortForm: { sort: '' },
      sortRule: { sort: [{ required: true, trigger: 'change', message: '请选择排序' }] },
      sortList: [],
      mcaId: ''
    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        mcatype: this.mcatype,
        ishelf: this.ishelf,
        mgid_one: this.mgid_one,
        mgid_two: this.mgid_two
      }
      markcardGet(params).then(res => {
        if (res.status === 1) {
          this.marketList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.marketList = []
          this.total = 0
        }
      })
    },
    // 一级分组下拉
    getInit () {
      markgroDown().then(res => {
        if (res.status === 1) {
          this.mgOneList = res.data.data
        } else {
          this.mgOneList = []
        }
      })
    },
    // 二级分组下拉
    oneChange (val) {
      this.mgTwoList = []
      this.mgid_two = ''
      markgroLow({ mgid: val }).then(res => {
        if (res.status === 1) {
          this.mgTwoList = res.data.data
        }
      })
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 排序弹窗
    changeSort (row) {
      this.sortShow = true
      this.mcaId = row.mcaid
      this.sortForm.sort = row.mcaid
      markdeSortGet({ mcaid: row.mcaid }).then(res => {
        if (res.status === 1) {
          this.sortList = res.data.data
        } else {
          this.sortList = []
        }
      })
    },
    // 排序[取消]
    sortCancel () {
      this.sortShow = false
      this.$refs.sortLabel.resetFields()
    },
    // 排序[确定]
    sortConfirm () {
      this.$refs.sortLabel.validate((valid) => {
        if (!valid) return
        const params = {
          id: this.sortForm.sort,
          mcaid: this.mcaId,
          mgid_one: this.mgid_one,
          mgid_two: this.mgid_two
        }
        markdeSortUpdate(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.sortCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 点击新增
    handleAdd () {
      this.showForm = true
      this.serveInfoEdit = null
    },
    // 点击编辑[展示]
    handEdit (row) {
      this.showForm = true
      this.serveInfoEdit = { ...row }
    },
    // 取消表单提交
    formCancel () {
      this.showForm = false
    },
    // 表单提交成功
    formSuccess () {
      this.showForm = false
      this.init()
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该服务, 是否继续?', '删除服务', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markcardDelete({ mcaid: row.mcaid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 上下架
    handishelf (row) {
      this.$confirm(`确定执行${!row.ishelf ? '上架' : '下架'}操作, 是否继续？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markserveIshelf({ mcaid: row.mcaid, ishelf: row.ishelf }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    handleSearch () {
      this.page = 1
      this.init()
      this.getInit()
    },
    handleReset () {
      this.page = 1
      this.mcatype = ''
      this.ishelf = ''
      this.mgid_one = ''
      this.mgid_two = ''
      this.mgTwoList = []
      this.init()
      this.getInit()
    }
  }
}
</script>

<style  scoped>

</style>
