<template>
  <div class="specialForm">
    <div class="specialFL">
      <div class="sflName" :class="serveFormType === '0' ? 'sfActive': ''" @click="changeForm('0')">基本信息</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '1' ? 'sfActive': ''" @click="changeForm('1')">适用门店</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '2' ? 'sfActive': ''" @click="changeForm('2')">适用服务</div>
      <div class="sflName"  v-if="serveInfoEdit !== null" :class="serveFormType === '3' ? 'sfActive': ''" @click="changeForm('3')">配套抵扣券</div>
    </div>
    <div class="specialFR">
      <!-- 基本信息 -->
      <div class="formItemType" v-if="serveFormType === '0'">
        <BasicInfo :serveInfoEdit="serveInfoEdit" @HandCancel="HandCancel" @formSuccess="formSuccess"></BasicInfo>
      </div>

      <!-- 适用门店 -->
      <div class="formItemType" v-if="serveFormType === '1'">
        <div class="table_list">
          <el-table
            ref="shopTable"
            :data="shopList"
            :header-cell-style="{background:'#EBF2FE'}"
            :row-key="getRowKeys"
            @row-click="handleRowClick"
            @selection-change="selectionChange">
            <el-table-column
              prop="mcaid"
              type="selection">
            </el-table-column>
            <el-table-column
              label="门店编号"
              type="index"
              width="100">
            </el-table-column>
            <el-table-column
              label="区域"
              prop="areas_name">
            </el-table-column>
            <el-table-column
              label="门店名称"
              prop="shopname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="负责人"
              prop="shopuser">
            </el-table-column>
            <el-table-column
              label="联系电话"
              prop="superphone">
            </el-table-column>
            <el-table-column
              label="门店类型"
              prop="shoptype">
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>

        <!--  提交按钮  -->
        <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
          <div class="settingCancel" @click="HandCancel">取消</div>
          <div class="settingConfirm" @click="handSetConfirm">确定</div>
        </div>
      </div>

      <!-- 适用服务 -->
      <div class="formItemType" v-if="serveFormType === '2'">
        <div v-if="!showform">
          <div class="settingAdd serveadd" @click="handleAdd"><span>添加服务</span></div>
          <div class="table_list">
            <el-table
              :data="applyServe"
              :header-cell-style="{background:'#EBF2FE'}">
              <el-table-column
                label="序号"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                label="服务名称"
                prop="servename"
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="服务分组"
                prop="sgname_one"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{scope.row.sgname_one}}/{{scope.row.sgname_two}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="ishelf"
                label="上架状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.ishelf == true" >上架</span>
                  <span v-if="scope.row.ishelf == false">下架</span>
                </template>
              </el-table-column>
              <el-table-column
                v-if="serveInfoEdit.mcatype === 1"
                label="搭配数量"
                prop="msenum">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.msenum" @change="handleChange" :min="1"  class="inputnum" ></el-input-number>
                </template>
              </el-table-column>
              <el-table-column
              label="操作"
              width="50">
              <template slot-scope="scope">
                <el-button type="text" size="small"  style="color:#F44C8E;" @click="handleRemove(scope.row)">移除</el-button>
              </template>
            </el-table-column>
            </el-table>
            <el-pagination
              @current-change="pageChange1"
              :current-page="page1"
              :page-sizes="[8]"
              :page-size="size1"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total1"
              class="settingPage">
            </el-pagination>
          </div>
          <!--  提交按钮  -->
          <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
            <div class="settingCancel" @click="HandCancel">取消</div>
            <div class="settingConfirm" @click="handConfirm">确定</div>
          </div>
        </div>
        <div class="box100" v-if="showform">
          <ServeList :serveInfoEdit="serveInfoEdit" @changeCancel="changeCancel" @serveFormSuccess="serveFormSuccess"></ServeList>
        </div>
      </div>

      <!-- 配套抵扣券 -->
      <div class="formItemType" v-if="serveFormType === '3'">
        <Coupon :serveInfoEdit="serveInfoEdit" @couponCancel="couponCancel" @couponFormSuccess="couponFormSuccess"></Coupon>
      </div>
    </div>
  </div>
</template>

<script>
import BasicInfo from '@/components/marketing/basicinfo'
import ServeList from '@/components/marketing/serveList'
import Coupon from '@/components/marketing/coupon'
import { markshopGet, markshopUpdate, markserveGet, markserveDelete, markserveNum } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  components: {
    BasicInfo,
    ServeList,
    Coupon
  },
  data () {
    return {
      serveFormType: '0',
      page: 1,
      size: 10,
      total: 0,
      shopList: [],
      multipleSelection: [],
      page1: 1,
      size1: 8,
      total1: 0,
      applyServe: [],
      showform: false
    }
  },
  created () {
  },
  methods: {
    // 适用门店
    init () {
      const params = {
        mcaid: this.serveInfoEdit.mcaid,
        page_index: this.page,
        page_size: this.size
      }
      markshopGet(params).then(res => {
        if (res.status === 1) {
          this.shopList = res.data.data
          this.total = res.data.recordcount
          this.$nextTick(() => {
            for (let i = 0; i < this.shopList.length; i++) {
              if (this.shopList[i].ischoose === 1) {
                this.$refs.shopTable.toggleRowSelection(this.shopList[i])
              }
            }
          })
        } else {
          this.shopList = []
          this.total = 0
        }
      })
    },
    changeForm (type) {
      this.serveFormType = type
      if (type === '1') { // 适用门店
        if (this.serveInfoEdit !== null) {
          this.init()
        }
      } else if (type === '2') { // 适用服务
        if (this.serveInfoEdit !== null) {
          this.getInit()
        }
      }
    },
    getRowKeys (row) {
      return row.mcaid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    // 适用门店 确定
    handSetConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择门店')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].shopid)
        }
        this.$confirm('确认是否绑定该门店', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markshopUpdate({ shopid: arr, mcaid: this.serveInfoEdit.mcaid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    // 适用服务查询(关联套餐的查询)
    getInit () {
      const params = {
        mcaid: this.serveInfoEdit.mcaid,
        page_index: this.page1,
        page_size: this.size1
      }
      markserveGet(params).then(res => {
        if (res.status === 1) {
          this.applyServe = res.data.data
          this.total1 = res.data.recordcount
        }
      })
    },
    // 适用服务 新增
    handleAdd () {
      this.showform = true
    },
    // 移除
    handleRemove (row) {
      this.$confirm('当前适用服务是否移除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markserveDelete({mseid: row.mseid}).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 适用服务取消
    changeCancel () {
      this.showform = false
      this.$emit('infoCancel')
      this.getInit()
    },
    // 搭配数量
    handleChange (value) {
      this.applyServe.msenum = value
    },
    // 适用服务数量确认
    handConfirm () {
      const arr = []
      for (let i = 0; i < this.applyServe.length; i++) {
        const obj = {mseid: this.applyServe[i].mseid, msenum: this.applyServe[i].msenum }
        arr.push(obj)
      }
      this.$confirm('当前搭配数量值是否变更', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markserveNum({mseid_json: arr}).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 取消
    HandCancel () {
      this.$emit('formCancel')
    },
    // 确定
    formSuccess () {
      this.$emit('formSuccess')
    },
    // 添加【适用服务】 成功
    serveFormSuccess () {
      this.showform = false
      this.getInit()
    },
    // 配套抵扣券取消
    couponCancel () {
      this.$emit('formCancel')
    },
    // 配套抵扣券确定
    couponFormSuccess () {
      this.getInit()
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    pageChange1 (page) {
      this.page1 = page
      this.getInit()
    },
  }
}
</script>

<style scoped>
.serveadd {
  margin-bottom: 20px;
}
.el-input-number{
  width: 130px;
  height: 40px;
}
</style>
