<template>
  <!-- 适用服务 添加服务-->
  <div class="box100">
    <div class="table_list">
      <el-table
        ref="shopTable"
        :data="serveList"
        :header-cell-style="{background:'#EBF2FE'}"
        :row-key="getKeys"
        @row-click="handleRowClick"
        @selection-change="selectionChange">
        <el-table-column
          prop="mcaid"
          type="selection"
          :reserve-selection="true">
        </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="100">
        </el-table-column>
        <el-table-column
          label="服务名称"
          prop="servename"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="服务分组"
          prop="sgname_one"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{scope.row.sgname_one}}/{{scope.row.sgname_two}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="ishelf"
          label="上架状态">
          <template slot-scope="scope">
            <span v-if="scope.row.ishelf == true" >上架</span>
            <span v-if="scope.row.ishelf == false">下架</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!--  提交按钮  -->
    <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
      <div class="settingCancel" @click="infoCancel">返回</div>
      <div class="settingConfirm" @click="infoConfirm">确定</div>
    </div>
  </div>
</template>

<script>
import { serveGet, markserveUpdate } from '@/api/market.js'
export default {
  props: ['serveInfoEdit'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      serveList: [],
      multipleSelection: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.serveInfoEdit !== null) {
        const params = {
          mcaid: this.serveInfoEdit.mcaid,
          page_index: this.page,
          page_size: this.size
        }
        serveGet(params).then(res => {
          if (res.status === 1) {
            this.serveList = res.data.data
            this.total = res.data.recordcount
            this.$nextTick(() => {
              this.$refs.shopTable.clearSelection(this.multipleSelection)
            })
            // this.$nextTick(() => {
            // for (let i = 0; i < this.serveList.length; i++) {
            //   if (this.serveList[i].ischoose === 1) {
            //       this.$refs.shopTable.toggleRowSelection(this.serveList[i])
            //     }
            //   }
            // })
          } else {
            this.serveList = []
            this.total = 0
          }
        })
      }
    },
    getKeys (row) {
      return row.mcaid
    },
    handleRowClick (row, column, event) {
      this.$refs.shopTable.toggleRowSelection(row)
    },
    selectionChange (val) {
      if (val.length === 0) {
        this.multipleSelection = []
      } else {
        this.multipleSelection = val
      }
    },
    // 取消
    infoCancel () {
      this.$emit('changeCancel')
    },
    // 确定
    infoConfirm () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择服务')
      } else {
        const arr = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          arr.push(this.multipleSelection[i].serveid)
        }
        this.$confirm('请确认您是否要对以上所选的服务进行添加', '添加服务', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          markserveUpdate({ serveid: arr, mcaid: this.serveInfoEdit.mcaid }).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.$emit('serveFormSuccess')
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {})
      }
    },
    pageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style>

</style>
